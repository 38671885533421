import { useRouter } from "next/router";
import LazyLoadedImg from "./LazyLoadedImg";
type LandingRecommendedCardProps = {
  slug: string;
  coverImg: string;
  title: string;
  description: string;
};

const LandingRecommendedCard = ({
  title,
  slug,
  coverImg,
  description,
}: LandingRecommendedCardProps) => {
  const router = useRouter();

  return (
    <div
      className="hover:scale-[103%] transition duration-[250ms] ease-in-out border-transparent border-2 hover:border-white hover:border-solid relative rounded-xl flex cursor-pointer w-full h-72 md:w-[40rem] md:h-[20rem]"
      onClick={() => router.isReady && router.push(`/profiles/${slug}`)}
    >
      <LazyLoadedImg srcSet={coverImg} className="w-full h-full rounded-xl " />
      <div className="absolute flex flex-col h-full w-full justify-end p-5">
        <div className="pt-3 px-3 text-xl flex justify-between w-full">
          {title}
        </div>
        <div className="pt-3 px-3 text-lg flex justify-between w-full">
          {description}
        </div>
      </div>
    </div>
  );
};

export default LandingRecommendedCard;
