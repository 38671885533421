import { DefaultTFuncReturn } from "i18next";

type LandingCardProps = {
  title: string;
  description?: string | DefaultTFuncReturn;
  icon?: JSX.Element;
  button?: JSX.Element;
  centeredDescription?: boolean;
};

const LandingCard: React.FC<LandingCardProps> = ({
  title,
  icon,
  description,
  button,
  centeredDescription,
}) => {
  return (
    <div className="flex flex-col items-center gap-12 justify-start ">
      <div className="flex gap-8">
        {icon}
        <h3 className="select-none leading-tight text-xl font-medium whitespace-pre text-primary">
          {title}
        </h3>
      </div>
      <p
        className={`text-lg w-96 whitespace-pre ${
          centeredDescription && "text-center"
        }`}
      >
        {description}
      </p>
      {button}
    </div>
  );
};

export default LandingCard;
